import axios from "axios";
import store from "./store/index";

let SERVICE_URL = "";

switch (process.env.VUE_APP_BUILD) {
  case "test":
    SERVICE_URL = "https://test.365equipo.com";
    break;
  case "localhost":
    SERVICE_URL = "http://localhost:3000";
    break;
  case "production":
    SERVICE_URL = "https://api.365equipo.com";
    break;
  // default:
  //   throw Error("SERVICE_URL incorrecto");
}
console.log("Entorno:", SERVICE_URL);
export const axiosInstance = axios.create({
  baseURL: SERVICE_URL,
  timeout: 30000,
  headers: { Authorization: "Bearer " },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getters["User/token"]; // Token almacenado en Vuex
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export { SERVICE_URL };
